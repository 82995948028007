import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center justify-content-between accordion-header" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "payment-service-icon bg-primary me-2" }
const _hoisted_4 = { class: "ps-2 lg" }
const _hoisted_5 = { class: "xl" }
const _hoisted_6 = { class: "xl" }
const _hoisted_7 = { class: "xl" }
const _hoisted_8 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_AppTableBodyCell = _resolveComponent("AppTableBodyCell")!
  const _component_UserInfo = _resolveComponent("UserInfo")!
  const _component_AppBadge = _resolveComponent("AppBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppTableBodyCell, { size: 'lg' }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_svg_icon, { icon: "esim" })
          ]),
          _createElementVNode("strong", _hoisted_4, _toDisplayString(_ctx.date), 1)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_AppTableBodyCell, { size: 'md' }, {
      default: _withCtx(() => [
        _createElementVNode("strong", _hoisted_5, _toDisplayString(_ctx.description), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppTableBodyCell, { size: 'md' }, {
      default: _withCtx(() => [
        _createElementVNode("strong", _hoisted_6, _toDisplayString(_ctx.paymentType), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_AppTableBodyCell, { size: 'md' }, {
      default: _withCtx(() => [
        _createVNode(_component_UserInfo, {
          name: `${_ctx.user?.firstName ?? ''} ${_ctx.user?.lastName ?? ''}`,
          description: `User ID: ${_ctx.user?.id}`,
          avatar: _ctx.user?.image ?? ''
        }, null, 8, ["name", "description", "avatar"])
      ]),
      _: 1
    }),
    _createVNode(_component_AppTableBodyCell, { size: 'sm' }, {
      default: _withCtx(() => [
        _createElementVNode("strong", _hoisted_7, [
          _createElementVNode("b", null, _toDisplayString(_ctx.amount ? '$' + _ctx.amount : ''), 1)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_AppTableBodyCell, { size: 'sm' }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_AppBadge, {
            rounded: false,
            size: 'sm',
            type: _ctx.status === 'Refund' ? 'danger' : 'success'
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.status), 1)
            ]),
            _: 1
          }, 8, ["type"])
        ])
      ]),
      _: 1
    })
  ]))
}